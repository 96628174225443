import { useContext } from 'react';
import { GlobalStateContext } from 'containers/GlobalContextProvider';

const getAcademiesArray = (translation) => [
  {
    type: 'developers',
    id: 'mce-group[69918]-69918-0',
    name: 'group[69918][1]',
    value: '1',
    label: translation['checkboxOption.developers'],
  },
  {
    type: 'front-end',
    id: 'mce-group[69918]-69918-1',
    name: 'group[69918][2]',
    value: '2',
    label: translation['checkboxOption.frontEnd'],
  },
  {
    type: 'testers',
    id: 'mce-group[69918]-69918-2',
    name: 'group[69918][4]',
    value: '4',
    label: translation['checkboxOption.testers'],
  },
  {
    type: 'professionals',
    id: 'mce-group[69918]-69918-3',
    name: 'group[69918][8]',
    value: '8',
    label: translation['checkboxOption.professionals'],
  },
];

export const createNewAcademiesArray = (academyTypes, translation) =>
  academyTypes.map((academyType) =>
    getAcademiesArray(translation).find(
      (academy) => academy.type === academyType
    )
  );

export const useMailchimpAcademies = (academiesList) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  const defaultAcademiesList = [
    'developers',
    'front-end',
    'testers',
    'professionals',
  ];

  return academiesList !== undefined && academiesList.length > 0
    ? createNewAcademiesArray(academiesList, translation)
    : createNewAcademiesArray(defaultAcademiesList, translation);
};
