import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ALL_ACADEMY_PAGES_EN_INFO {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              templateKey: {
                in: ["academyStudentsSingle", "academyProfessionals"]
              }
              isPublished: { eq: true }
            }
            fields: { slug: { regex: "//en//" } }
          }
          sort: { order: ASC, fields: frontmatter___info___startDate }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                shortDescription
                tags
                info {
                  type
                  startDate
                  endDate
                  applicationDeadline
                  admissionStartDate
                  admissionEndDate
                  duration
                  location
                  tuition
                }
              }
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges.map(({ node }) => node);
};
