import { contactInfo } from 'utilities';

export const getLocationsByCountry = (country) => {
  const contactsAndLocations = contactInfo()[0];
  const locations = contactsAndLocations.frontmatter.locations;
  const newLocationsArray = [];

  locations.forEach((location) => {
    return location.country === country && newLocationsArray.push(location);
  });

  return newLocationsArray;
};
