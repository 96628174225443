const getOrdinalDay = (dateString, lang) => {
  const date = new Date(dateString);
  const day = date.getDate();

  const addOrdinalSuffix = () => {
    if (day > 3 && day < 21) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };
  switch (lang) {
    case 'lt':
      return `${day} d.`;
    case 'en':
      return `${day}${addOrdinalSuffix()}`;
    default:
      return `${day}`;
  }
};

const monthsInLithuanian = [
  'sausio',
  'vasario',
  'kovo',
  'balandžio',
  'gegužės',
  'birželio',
  'liepos',
  'rugpjūčio',
  'rugsėjo',
  'spalio',
  'lapkričio',
  'gruodžio',
];

const monthsInPolish = [
  'styczeń',
  'luty',
  'marzec',
  'kwiecień',
  'maj',
  'czerwiec',
  'lipiec',
  'sierpień',
  'wrzesień',
  'październik',
  'listopad',
  'grudzień',
];

const getMonthName = (dateString, lang) => {
  const month = new Date(dateString).getMonth();

  switch (lang) {
    case 'lt':
      return monthsInLithuanian[month];
    case 'pl':
      return monthsInPolish[month];
    default:
      return new Date(dateString).toLocaleString('en-US', { month: 'long' });
  }
};

const getFullMonthAndDay = (dateString, lang) =>
  `${getMonthName(dateString, lang)} ${getOrdinalDay(dateString, lang)}`;

const getFormattedTime = (value) => {
  if (Number.isNaN(Number(value))) {
    return value;
  }
  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
};

export { getFullMonthAndDay, getOrdinalDay, getMonthName, getFormattedTime };
