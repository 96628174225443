import queryChaptersList from './queryChaptersList';
import queryArticlesList from './queryArticlesList';

const chaptersArticles = (articleId) => {
  const chaptersQuery = queryChaptersList();
  const articlesList = queryArticlesList();
  const chapters = [];
  const chaptersList = chaptersQuery[0].node.frontmatter.chapters;
  let chapterNumber = 0;

  chaptersList.forEach((chapter) => {
    const singleChapter = [];
    chapterNumber += 1;

    singleChapter.number = chapterNumber;
    singleChapter.name = chapter.name;
    singleChapter.isActive = false;
    singleChapter.articles = [];

    if (chapter.articles.length > 0) {
      chapter.articles.forEach((articlePath) => {
        const article = articlesList.find(
          ({ node: e }) =>
            e.parent.relativePath ===
            articlePath.replace('src/cms/secret-source/articles/', '')
        );

        if (typeof article !== 'undefined') {
          const slug = `/secret-source${article.node.fields.slug}`;

          article.node.tableOfContents = article.node.tableOfContents.replace(
            '/secretSourceArticle/',
            ''
          );

          singleChapter.isActive =
            article.node.id === articleId ? true : singleChapter.isActive;

          singleChapter.articles.push({
            ...article.node,
            slug,
            isActive: article.node.id === articleId,
          });
        }
      });
    }
    if (singleChapter.articles.length > 0) {
      chapters.push({ ...singleChapter });
    }
  });

  return chapters;
};

export default chaptersArticles;
