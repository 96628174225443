import DevelopersIcon from 'images/course-developers-icon.svg';
import FrontEndIcon from 'images/course-front-end-icon.svg';
import TestersIcon from 'images/course-testers-icon.svg';
import Professionals from 'images/course-professionals-icon.svg';

const getAcademyIcon = (academyType) => {
  switch (academyType) {
    case 'front-end':
      return FrontEndIcon;
    case 'testers':
      return TestersIcon;
    case 'professionals':
      return Professionals;
    default:
      return DevelopersIcon;
  }
};

export { getAcademyIcon };
