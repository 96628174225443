import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import FocusLock from 'react-focus-lock';

import './scss/header.scss';
import { languageOptions } from 'lang';
import { createCookie, getUrlObject } from 'utilities';
import { LanguageSelect } from 'components/languageSelect';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import Logo from 'icons/sourcery-logo.svg';
import { SECRET_SOURCE_LANDING } from 'shared/routes';
import { AcademiesButton } from './AcademiesButton';

const Header = ({
  onNavigationClick,
  isMobileNavOpen,
  setMobileNavState,
  hasLanguageDropdown,
}) => {
  const TABLET_BREAKPOINT = 1300;

  if (typeof window === 'undefined') {
    global.window = {
      location: {
        pathname: '',
      },
    };
  }

  if (typeof document === 'undefined') {
    global.document = {
      cookie: '',
    };
  }

  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  const setLanguageById = (languageId) =>
    languageOptions.find((lang) => lang.id === languageId);

  const english = setLanguageById('en');
  const polish = setLanguageById('pl');
  const lithuanian = setLanguageById('lt');

  const languageCookie = document.cookie.replace(
    /(?:(?:^|.*;\s*)language\s*=\s*([^;]*).*$)|^.*$/,
    '$1'
  );

  const [urlObject, setUrlObject] = useState(null);

  useEffect(() => {
    const path = window.location.pathname;

    if (hasLanguageDropdown) {
      if (path.startsWith('/en/')) {
        languageContext.setLanguage(english);
        createCookie('language', 'en', 30);
      }
      if (path.startsWith('/pl/')) {
        languageContext.setLanguage(polish);
        createCookie('language', 'pl', 30);
      }
      if (path.startsWith('/lt/')) {
        languageContext.setLanguage(lithuanian);
        createCookie('language', 'lt', 30);
      }
    }

    setUrlObject(getUrlObject(path));
  }, [window.location.pathname]);

  const navStateLabel = isMobileNavOpen
    ? translation['navigation.close']
    : translation['navigation.open'];

  const handleNavigationClick = () => {
    onNavigationClick();
  };

  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = useCallback(() => {
    setWidth(window.innerWidth);

    if (width > TABLET_BREAKPOINT) {
      setMobileNavState(false);
    }
  }, [width]);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <div className="header">
      <Link className="header__logo" to="/">
        <span className="header__logo-label">
          {translation['navigation.homePage']}
        </span>
        <Logo />
      </Link>
      <div className="header__nav-wrapper">
        {hasLanguageDropdown && urlObject && (
          <LanguageSelect
            isMobileNavOpen={isMobileNavOpen}
            className="language-dropdown--desktop"
            urlObject={urlObject}
          />
        )}
        <FocusLock disabled={!isMobileNavOpen}>
          <nav className="header__nav">
            <div
              className={classNames('header__button-wrapper', {
                'header__button-wrapper--language-select': hasLanguageDropdown,
              })}
            >
              {hasLanguageDropdown &&
                urlObject &&
                width <= TABLET_BREAKPOINT && (
                  <LanguageSelect
                    isMobileNavOpen={isMobileNavOpen}
                    width={width}
                    urlObject={urlObject}
                  />
                )}
              <button
                type="button"
                className="nav-toggle-button"
                onClick={handleNavigationClick}
              >
                <span className="nav-toggle-button__content">
                  {navStateLabel}
                </span>
              </button>
            </div>
            <div
              className={classNames('header__nav-list', {
                inverted: isMobileNavOpen,
              })}
            >
              <AcademiesButton
                isMobileNavOpen={isMobileNavOpen}
                languageCookie={languageCookie}
              />
              <div className="header__nav-item">
                <Link
                  className="header__nav-item-text"
                  to={SECRET_SOURCE_LANDING}
                >
                  The Secret Source
                </Link>
              </div>
            </div>
          </nav>
        </FocusLock>
      </div>
    </div>
  );
};

Header.defaultProps = {
  hasLanguageDropdown: false,
};

Header.propTypes = {
  isMobileNavOpen: PropTypes.bool,
  hasLanguageDropdown: PropTypes.bool,
  onNavigationClick: PropTypes.func,
  setMobileNavState: PropTypes.func,
};

export { Header };
