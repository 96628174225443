export const BOOK_DOWNLOAD_PAGE = '/secret-source/download-book/';
export const SECRET_SOURCE_LANDING = '/secret-source/';

export const ACADEMY_STUDENTS_LANDING_POLAND = {
  en: '/en/academy/juniors/poland/',
  pl: '/pl/academy/juniors/',
};
export const ACADEMY_STUDENTS_LANDING_LITHUANIA = {
  en: '/en/academy/juniors/lithuania/',
  lt: '/lt/akademija/jauniesiems-specialistams/',
};
export const ACADEMY_STUDENTS_REGISTRATION = {
  en: '/en/academy/juniors/register/',
  lt: '/lt/akademija/jauniesiems-specialistams/registracija/',
};
export const ACADEMY_STUDENTS_DEVELOPERS_DOTNET = {
  en: '/en/academy/juniors/lithuania/developers-dotnet/',
  lt: '/lt/akademija/jauniesiems-specialistams/programuotojams-dotnet/',
};
export const ACADEMY_STUDENTS_DEVELOPERS_JAVA = {
  en: '/en/academy/juniors/lithuania/developers-java/',
  lt: '/lt/akademija/jauniesiems-specialistams/programuotojams-java/',
};
export const ACADEMY_STUDENTS_FRONTEND = {
  en: '/en/academy/juniors/lithuania/front-end/',
  lt: '/lt/akademija/jauniesiems-specialistams/front-end/',
};
export const ACADEMY_STUDENTS_TESTERS = {
  en: '/en/academy/juniors/lithuania/testers/',
  lt: '/lt/akademija/jauniesiems-specialistams/testuotojams/',
};
export const ACADEMY_STUDENTS_DEVELOPERS_JAVA_POLAND = {
  en: '/en/academy/juniors/poland/developers-java/',
  pl: '/pl/academy/juniors/developers-java/',
};

export const ACADEMY_STUDENTS_TESTERS_POLAND = {
  en: '/en/academy/juniors/poland/testers/',
  pl: '/pl/academy/juniors/testers/',
};
export const ACADEMY_KIDS_LANDING = {
  en: '/en/academy/kids/',
  lt: '/lt/akademija/vaikams/',
};
export const ACADEMY_KIDS_MENTORS_LANDING = {
  en: '/en/academy/kids/mentors/',
  lt: '/lt/akademija/vaikams/mentoriams/',
};
export const ACADEMY_PROFESSIONALS_DOTNET_POLAND = {
  en: '/en/academy/professionals/developers-dotnet-poland/',
  pl: '/pl/academy/professionals/developers-dotnet-poland/',
};

export const ACADEMY_PROFESSIONALS_LANDING =
  '/en/academy/professionals/product/';

export const THOUGHT_LEADERS_AURIMAS_ROUTE =
  '/thought-leaders/aurimas-adomavicius/';
export const THOUGHT_LEADERS_LAURA_ROUTE = '/thought-leaders/laura-graves/';
export const THOUGHT_LEADERS_VIKTORAS_ROUTE =
  '/thought-leaders/viktoras-gurgzdys/';
export const THOUGHT_LEADERS_ED_ROUTE = '/thought-leaders/ed-price/';

export const PRIVACY_POLICY = {
  en: '/en/privacy-policy/',
  lt: '/lt/privatumo-politika/',
};
