import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query CONTACT_INFO {
        allMarkdownRemark(
          filter: { frontmatter: { pageId: { eq: "siteContactInfo" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                contacts {
                  academy
                  email
                  phone
                }
                locations {
                  city
                  postalCode
                  address
                  country
                }
              }
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges.map(({ node }) => node);
};
