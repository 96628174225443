import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query SS_ARTICLES_LIST {
        allMarkdownRemark(
          filter: {
            fields: { sourceInstanceName: { eq: "secretSourceArticles" } }
            frontmatter: { isPublished: { eq: true } }
          }
        ) {
          edges {
            node {
              id
              frontmatter {
                templateKey
                title
              }
              fields {
                slug
              }
              parent {
                ... on File {
                  name
                  relativeDirectory
                  relativePath
                }
              }
              tableOfContents(
                maxDepth: 2
                pathToSlugField: "frontmatter.templateKey"
              )
            }
          }
        }
      }
    `
  );

  return allMarkdownRemark.edges;
};
