import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ArrowDown from 'icons/arrow-down.svg';
import LanguageIcon from 'icons/language-icon.svg';
import {
  useCloseOnOutsideClick,
  getUrlByLanguage,
  createCookie,
} from 'utilities';
import { languageOptions } from 'lang';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import './scss/styles.scss';

const LanguageSelect = ({ width, className, isMobileNavOpen, urlObject }) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  const [isDropdownOpen, setDropdownState] = useState(false);
  const dropdownStateLabel = isDropdownOpen
    ? translation['navigation.dropdown.hide']
    : translation['navigation.dropdown.show'];
  const toggleDropdown = () => setDropdownState(!isDropdownOpen);

  const dropdownRef = useRef(null);
  useCloseOnOutsideClick(dropdownRef, toggleDropdown, isDropdownOpen);

  const selectedLanguage = languageContext.language.text;

  const handleClick = (lang) => {
    const selectedLanguageId = lang.id;
    setDropdownState(false);

    if (selectedLanguageId !== languageContext.language.id) {
      languageContext.setLanguage(lang);
      createCookie('language', selectedLanguageId, 30);
      window.location.pathname = getUrlByLanguage(
        selectedLanguageId,
        urlObject
      );
    }
  };

  const filteredLanguageOptions = languageOptions.filter((item) =>
    Object.keys(urlObject).includes(item.id)
  );

  return (
    <div
      className={classNames('language-dropdown', className && `${className}`, {
        'open-dropdown': isDropdownOpen && !isMobileNavOpen,
      })}
      onMouseEnter={isDropdownOpen ? null : toggleDropdown}
      onMouseLeave={isDropdownOpen ? toggleDropdown : null}
      ref={dropdownRef}
    >
      <button
        type="button"
        aria-expanded={isDropdownOpen}
        className="language-dropdown__button"
        onKeyPress={toggleDropdown}
        aria-label={`${selectedLanguage}, ${
          translation['button.selectLanguage']
        }`}
      >
        <div className="language-dropdown__button-content">
          <LanguageIcon />
          <span className="language-dropdown__button-text">
            {selectedLanguage}
          </span>
        </div>
        <ArrowDown />
        <span className="sr-only">{dropdownStateLabel}</span>
      </button>
      <div className="language-dropdown__list-wrapper inverted">
        <LanguageIcon />
        <div className="language-dropdown__list">
          {filteredLanguageOptions.map((lang) => (
            <button
              className="language-dropdown__item-button"
              type="button"
              key={lang.id}
              value={lang.text}
              onClick={() => handleClick(lang)}
            >
              {width && width <= 900 ? lang.id.toUpperCase() : lang.text}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

LanguageSelect.propTypes = {
  className: PropTypes.string,
  isMobileNavOpen: PropTypes.bool,
  width: PropTypes.number,
  urlObject: PropTypes.oneOfType([PropTypes.object]),
};

export { LanguageSelect };
