import React, { useContext } from 'react';

import './scss/footer.scss';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import FacebookIcon from 'icons/facebook-icon.svg';
import LinkedInIcon from 'icons/linkedin-icon.svg';

const Footer = () => {
  const currentDate = new Date();

  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  return (
    <div className="footer">
      <div className="footer__social-links">
        <a
          className="footer__social-link"
          aria-label={`Facebook (${translation['button.newWindow']})`}
          target="_blank"
          href="https://www.facebook.com/Cognizant"
          rel="noopener noreferrer"
        >
          Facebook
          <FacebookIcon />
        </a>
        <a
          className="footer__social-link"
          aria-label={`LinkedIn (${translation['button.newWindow']})`}
          target="_blank"
          href="https://www.linkedin.com/company/cognizant/"
          rel="noopener noreferrer"
        >
          LinkedIn
          <LinkedInIcon />
        </a>
      </div>
      <div className="footer__content-wrapper">
        <div className="footer__content">
          <p className="footer__copyright">
            Copyright &copy; {`${currentDate.getFullYear()} Cognizant`}
          </p>
        </div>
        <p className="footer__disclaimer">
          Sourcery Academy&trade; is a trademark of Cognizant and may be
          registered in certain jurisdictions.
        </p>
      </div>
    </div>
  );
};

export { Footer };
