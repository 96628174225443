import { useEffect } from 'react';

const useCloseOnOutsideClick = (ref, toggleFunction, elementState) => {
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target) && elementState) {
      toggleFunction();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleClickOutside);
    };
  }, [elementState, ref, toggleFunction]);
};

export default useCloseOnOutsideClick;
