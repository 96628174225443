import {
  ACADEMY_STUDENTS_LANDING_LITHUANIA,
  ACADEMY_STUDENTS_LANDING_POLAND,
  ACADEMY_STUDENTS_DEVELOPERS_DOTNET,
  ACADEMY_STUDENTS_DEVELOPERS_JAVA,
  ACADEMY_STUDENTS_DEVELOPERS_JAVA_POLAND,
  ACADEMY_STUDENTS_FRONTEND,
  ACADEMY_STUDENTS_TESTERS,
  ACADEMY_STUDENTS_TESTERS_POLAND,
  ACADEMY_KIDS_LANDING,
  ACADEMY_KIDS_MENTORS_LANDING,
  ACADEMY_PROFESSIONALS_DOTNET_POLAND,
} from 'shared/routes';

const urlObjectsArray = [
  ACADEMY_STUDENTS_LANDING_LITHUANIA,
  ACADEMY_STUDENTS_LANDING_POLAND,
  ACADEMY_STUDENTS_DEVELOPERS_DOTNET,
  ACADEMY_STUDENTS_DEVELOPERS_JAVA,
  ACADEMY_STUDENTS_FRONTEND,
  ACADEMY_STUDENTS_TESTERS,
  ACADEMY_STUDENTS_DEVELOPERS_JAVA_POLAND,
  ACADEMY_STUDENTS_TESTERS_POLAND,
  ACADEMY_KIDS_LANDING,
  ACADEMY_KIDS_MENTORS_LANDING,
  ACADEMY_PROFESSIONALS_DOTNET_POLAND,
];
function checkUrl(url) {
  const pattern = /.*\/$/;
  const hasTrailingSlash = pattern.test(url);
  return hasTrailingSlash ? url : `${url}/`;
}

export const getUrlObject = (currentPath) => {
  const path = checkUrl(currentPath);
  const filteredUrlObject = urlObjectsArray.find(
    (urlObject) =>
      urlObject.lt === path || urlObject.en === path || urlObject.pl === path
  );

  return filteredUrlObject;
};
