import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query SS_CHAPTERS_LIST {
        allMarkdownRemark(
          filter: { frontmatter: { pageId: { eq: "chaptersList" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                chapters {
                  name
                  articles
                }
              }
            }
          }
        }
      }
    `
  );

  return allMarkdownRemark.edges;
};
