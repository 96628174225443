import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import CookieConsent from 'react-cookie-consent';

import './scss/layout.scss';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { Header } from 'components/header';
import { SEO } from 'components/SEO';
import { getUrlByLanguage } from 'utilities';
import { PRIVACY_POLICY } from 'shared/routes';
import { Footer } from './Footer';

const PageMaster = ({
  children,
  darkTheme,
  darkHeader,
  metaTitle,
  metaImage,
  metaDescription,
  metaKeywords,
  pathname,
  type,
  author,
  hasLanguageDropdown,
  className,
  hasHeader,
  hasFooter,
}) => {
  const [isMobileNavOpen, setMobileNavState] = useState(false);
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;
  const toggleMobileNav = () => setMobileNavState(!isMobileNavOpen);

  return (
    <div className="page">
      <noscript id="gatsby-noscript" className="noscript">
        <div className="noscript__wrapper">
          <div className="noscript__text">
            This app works best with JavaScript enabled.
          </div>
        </div>
      </noscript>
      <Helmet>
        <html
          className={classNames(
            {
              'open-mobile-navigation': isMobileNavOpen,
              'dark-theme': darkTheme,
              'dark-header': darkHeader,
            },
            className
          )}
          lang={languageContext.language.id}
        />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <noscript>
          {`
            <link rel="stylesheet" type="text/css" href="/noscript-styles.css" />
          `}
        </noscript>
      </Helmet>
      <SEO
        title={metaTitle}
        image={metaImage}
        description={metaDescription}
        keywords={metaKeywords}
        type={type}
        author={author}
        pathname={pathname}
        locale={languageContext.language.id === 'lt' ? 'lt_LT' : 'en_US'}
      />
      <CookieConsent
        location="none"
        buttonText={translation['button.accept']}
        cookieName="gatsby-gdpr-google-tagmanager"
        disableStyles
        containerClasses="cookie-consent"
        contentClasses="cookie-consent__message"
        buttonClasses="cookie-consent__accept-btn btn"
      >
        {translation['cookieConsent.text']}
        <a
          className="cookie-consent__link"
          href={getUrlByLanguage(languageContext.language.id, PRIVACY_POLICY)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {translation['button.learnMore']}
        </a>
      </CookieConsent>
      {hasHeader && (
        <header className="page__header">
          <Header
            hasLanguageDropdown={hasLanguageDropdown}
            isMobileNavOpen={isMobileNavOpen}
            onNavigationClick={toggleMobileNav}
            setMobileNavState={setMobileNavState}
          />
        </header>
      )}
      <main aria-hidden={isMobileNavOpen} className="page__body">
        {children}
      </main>
      {hasFooter && (
        <footer aria-hidden={isMobileNavOpen} className="page__footer">
          <Footer />
        </footer>
      )}
    </div>
  );
};

PageMaster.defaultProps = {
  hasHeader: true,
};

PageMaster.propTypes = {
  children: PropTypes.node.isRequired,
  darkTheme: PropTypes.bool,
  darkHeader: PropTypes.bool,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  metaKeywords: PropTypes.arrayOf(PropTypes.string),
  metaImage: PropTypes.string,
  pathname: PropTypes.string,
  author: PropTypes.string,
  type: PropTypes.string,
  hasLanguageDropdown: PropTypes.bool,
  className: PropTypes.string,
  hasHeader: PropTypes.bool,
  hasFooter: PropTypes.bool,
};

export { PageMaster as Layout };
