import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        defaultImage: image
        defaultKeywords: keywords
        twitterUsername
        facebookPageName
        facebookAppId
        facebookPageId
        defaultAuthor: author
        type
      }
    }
  }
`;

const SEO = ({
  title,
  description,
  image,
  keywords,
  pathname,
  author,
  type,
  locale,
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          defaultDescription,
          defaultAuthor,
          siteUrl,
          defaultImage,
          defaultKeywords,
          twitterUsername,
          facebookPageName,
          facebookPageId,
          facebookAdminId,
          facebookAppId,
          defaultType,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        author: author || defaultAuthor,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        keywords: keywords || defaultKeywords,
        siteUrl: `${siteUrl}${pathname || '/'}`,
        type: type || defaultType,
        locale,
      };
      return (
        <>
          <Helmet title={seo.title}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.keywords && <meta name="keywords" content={seo.keywords} />}
            {seo.siteUrl && <meta property="og:url" content={seo.siteUrl} />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.title && <meta property="og:site_name" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.type && <meta property="og:type" content={seo.type} />}
            {seo.author && <meta property="og:author" content={seo.author} />}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta property="og:image:width" content="1080" />
            <meta property="og:image:height" content="1080" />
            {seo.locale && <meta property="og:locale" content={seo.locale} />}
            {facebookPageName && (
              <meta
                property="article:publisher"
                content={seo.facebookPageName}
              />
            )}
            {facebookAppId && <meta name="fb:app_id" content={facebookAppId} />}
            {facebookAdminId && (
              <meta name="fb:admins" content={facebookAdminId} />
            )}
            {facebookPageId && (
              <meta name="fb:pages" content={facebookPageId} />
            )}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
            {twitterUsername && (
              <meta name="twitter:site" content={twitterUsername} />
            )}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
          </Helmet>
        </>
      );
    }}
  />
);
export { SEO };

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  pathname: PropTypes.string,
  author: PropTypes.string,
  type: PropTypes.string,
  locale: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  keywords: null,
  pathname: null,
  author: null,
  type: null,
};
