import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ACADEMY_STUDENTS_PAGES_POLAND_EN_INFO {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              templateKey: { eq: "academyStudentsSingle" }
              isPublished: { eq: true }
            }
            fields: { slug: { regex: "/^/en/.*poland.*//" } }
          }
          sort: { fields: frontmatter___info___startDate, order: ASC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                shortDescription
                tags
                info {
                  type
                  startDate
                  endDate
                  applicationDeadline
                  admissionStartDate
                  admissionEndDate
                  duration
                  location
                  tuition
                }
              }
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges.map(({ node }) => node);
};
