export const getUrlByLanguage = (languageId, urlObject) => {
  if (urlObject)
    switch (languageId) {
      case 'en':
        return urlObject.en;
      case 'pl':
        return urlObject.pl;
      default:
        return urlObject.lt;
    }
  return undefined;
};
